import { BrowserRouter } from "react-router-dom";
import React, { useState } from "react";
import Modal from "./Components/Modal";
import StepModal from "./Components/StepModal";

function App() {
  const [modal, setModal] = useState(true);
  const [stepModal, setStepModal] = useState(false);
  return (
    <BrowserRouter>
      <Modal setModal={setModal} modal={modal} setStepModal={setStepModal} />
      {stepModal && (
        <StepModal setModal={setModal} setStepModal={setStepModal} />
      )}
    </BrowserRouter>
  );
}

export default App;
