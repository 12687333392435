import React, { useEffect, useState } from "react";
// import { AiOutlineClose } from "react-icons/ai";
import walletConnect from "../assets/walletConnect.svg";
import ledger from "../assets/ledger.png";
import Ethereum from "../assets/etherium.png";
import Rainbow from "../assets/rainbow.avif";
import phantom from "../assets/phantom.svg";
import trust from "../assets/trust.svg";
import metamask from "../assets/metamask.webp";
import solflare from "../assets/solfare.svg";
import torus from "../assets/torus.svg";
import sollet from "../assets/sollet.png";
import tokenProject from "../assets/tokenProject.svg";
import coinbase from "../assets/coinbase.svg";
import coin98 from "../assets/coin98.svg";
import UniPass from "../assets/unipass.webp";

const Modal = ({ setModal, setStepModal }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setModal(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1000 milliseconds = 1 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, [setModal]);

  const walletStyle = {
    walletBtn:
      "flex items-center border border-white/20 bg-[#F9F9FA] text-gray-500 hover:opacity-80 p-3 space-x-2 rounded-lg cursor-pointer",
  };
  return (
    <div className="transition-10 duration-50 justify-center items-center flex fixed top-0 inset-0 p-2  z-50 outline-0 bg-[#121A35]">
      <div className="md:w-[40rem] w-full  py-8 px-5 rounded-xl border border-white/20 bg-[#605FFF]/60 drop-shadow-2xl">
        {loading ? (
          <div className="flex justify-center min-h-[400px] items-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="text-white font-[600] text-lg ">
              <span />
              <p className="text-white text-center">Connect your wallet</p>
              {/* <AiOutlineClose
                onClick={() => setModal(false)}
                className="font-[800] cursor-pointer text-white "
              /> */}
            </div>

            <div className="my-4 grid grid-cols-2 gap-2">
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={metamask} alt="metamask" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">MetaMask</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={phantom} alt="dakjdga" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Phantom</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={solflare} alt="dakjdga" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Solflare</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={torus} alt="torus" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Torus </p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img className="w-8" src={ledger} alt="ledger" />
                <p className="lg:text-md text-xs font-[500]">Ledger</p>
              </div>

              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={walletConnect} alt="walletConnect" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">WalletConnect</p>
              </div>
            </div>
            <p className="md:mt-10 mt-5 mb-2 text-sm font-[500] text-slate-400 uppercase">
              Other Wallets
            </p>
            <div className="grid grid-cols-2 gap-2">
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={trust} alt="torus" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Trust </p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img className="w-8" src={Ethereum} alt="Ethereum" />
                <p className="lg:text-md text-xs font-[500]">Ethereum</p>
              </div>

              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={UniPass} alt="walletConnect" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Unipass</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={coinbase} alt="walletConnect" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Coinbase Wallet</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={tokenProject} alt="walletConnect" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">TokenProject</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={sollet} alt="sollet" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Sollet</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={Rainbow} alt="walletConnect" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">Rainbow</p>
              </div>
              <div
                className={walletStyle.walletBtn}
                onClick={() => {
                  setStepModal(true);
                  setModal(false);
                }}
              >
                <img src={coin98} alt="walletConnect" className="w-8" />
                <p className="lg:text-md text-xs font-[500]">coin98</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
